import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline } from "mdbreact";
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBInput, MDBDropdown, MDBSelect, MDBBtn
} from 'mdb-react-ui-kit';
import Link from '@material-ui/core/Link';
import CloudDownloadTwoToneIcon from '@material-ui/icons/CloudDownloadTwoTone';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import GridTable from '../Grid/GridTable';
import GiftsGridTable from '../Grid/GiftsGridTable';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ImportPeople from '../Import/ImportPeople';
import Import from '../Import/Import';
import PreviewImport from '../Import/previewImport';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import { Base64 } from 'js-base64';
import XLSX from 'xlsx';
import LookupModal from '../LookupPeople/LookupModal';
import { Row } from 'rsuite';
import {
    MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter
}
    from 'mdbreact';

class Grid extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            rows: props.data,
            recipientToAddBySearch: props.recipientToAddBySearch,
            selectedTab: props.defaultTab,
            gridOpen: false,
            gridCollapse: false,
            fromPage: props.fromPage,
            giftsGridData: props.giftsGridData,
            showImportPeople: false,
            showLookupPeople: false,
            giftSenders: props.giftSenders,
            giftRecipients: props.giftRecipients,
            gifters: props.gifters,
            page: 0,
            rowsPerPage: 10,
            vendorsList: props.vendorsList,
            giftId: props.giftId,
            pageName: props.fromPage == 'event' ? 'Lookup Invitees' : 'Lookup Senders',
            isGiftTabClicked: false,
            disabled: props.disabled ? props.disabled : false,
            masterId: null,
            toggleMessageModal: false,
            giftMessage: props.giftMessage,
            gridLoader: props.gridLoader
        }
    }
    handleTabClick = (value) => {
        let name = '';
        if (this.state.fromPage != 'event') {
            name = value == 'sendersTab' ? 'Lookup Senders' : 'Lookup Recipients'
        } else name = 'Lookup Invitees';
        this.setState({
            selectedTab: value,
            gridOpen: false,
            gridCollapse: false,
            page: 0,
            rowsPerPage: 10,
            isGiftTabClicked: value == 'giftsTab' ? true : false,
            pageName: name
        })

        this.props.tabSelection(value)

    }
    getLookupButtonName = () => {
        let name = '';
        if (this.state.fromPage != 'event') {
            name = this.state.selectedTab == 'sendersTab' ? 'Lookup Senders' : 'Lookup Recipients'
        } else name = 'Lookup Invitees';
        return name;
    }

    toggleImportPeople = () => {
        this.setState({ showImportPeople: !this.state.showImportPeople });
    }
    toggleLookupPeople = () => {
        let pageName = '';
        if (this.state.fromPage == 'event') {
            pageName = 'Lookup Invitees'
        } else if (this.state.fromPage == 'gift') {
            pageName = this.state.selectedTab == 'sendersTab' ? 'Lookup Senders' : 'Lookup Recipients';
        }
        this.setState({ showLookupPeople: !this.state.showLookupPeople, pageName: pageName });
    }

    updateData = (finalResult) => {
        if (this.state.fromPage === 'event') {
            finalResult.map((item) => { item['RSVP_status'] = null })
            this.setState({ rows: finalResult });
            this.props.updatePeopleFromImport(finalResult);
        }
        else if (this.state.fromPage === 'gift') {
            // if (this.state.selectedTab === 'recipientsTab')
            //     this.setState({ giftRecipients: finalResult });
            // else if (this.state.selectedTab === 'sendersTab') {
            //     this.setState({ giftSenders: finalResult });
            // }
        }
        this.props.setImportState(finalResult, this.state.selectedTab)
    }
    handleAddGuest = () => {
        let gifters = this.state.gifters;
        let distributions = gifters[0]?.distributions ? gifters[0]?.distributions : [];
        let newDistribution = {
            distribution_amount: 0,
            gift_distribution_id: null,
            gift_id: 0,
            gift_name: "",
            gift_price: 0,
            quantity: 1,
            recipients: [],
            vendor_id: 0,
            vendor_name: "", gift_message: "",
        }
        distributions.push(newDistribution);
        if (gifters.length == 0) {
            let r = {
                gift_event_card_id: this.state.giftId,
                gift_other_cost: 0,
                gift_shipping_delivery_cost: 0,
                gift_tax: 0,
                gift_total_cost: 0,
                gift_total_price: 0,
            }
            gifters = [r]
        }
        gifters[0]['distributions'] = distributions;
        this.setState({
            gifters: gifters
        })
    }

    /*
    previewExcel = () => {
        this.setState({ showImportPeople: !this.state.showImportPeople });
        this.setState({ showPreviewImport: !this.state.showPreviewImport });
        if (this.state.selectedFile != undefined) {
            let formData = new FormData();
            var reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            if (rABS) reader.readAsBinaryString(this.state.selectedFile);
            else reader.readAsArrayBuffer(this.state.selectedFile);
            //reader.readAsArrayBuffer(this.state.selectedFile);      
            //reader.readAsBinaryString(this.state.selectedFile);    
            reader.onloadend = (evt) => {
                if (evt.target.readyState == FileReader.DONE) {

                    var data = evt.target.result;// reader.result; //new Uint8Array(reader.result);//
                    //Base64.fromUint8Array(reader.result);
                    //var workbook = XLSX.read(data, { type: 'binary' });
                    //formData.append('excel_file', data );

                    var workbook = XLSX.read(evt.target.result, {
                        type: rABS ? 'binary' : 'array'
                    });
                    var sheet_name_list = workbook.SheetNames;
                    var json_input = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { header: 1, defval: "" });
                    formData.append('excel_file', json_input);
                    GifterService.postData(Constants.GifterServiceBaseUrl + '/importPreview', json_input)
                        .then((response) => {
                            console.log("Gift Service selected file  for import preview: " + response.data);

                            var results = response.data.map((row) => ({ ...row, id: row.excel_row_no }));


                            this.setState({
                                importPeopleData: {
                                    ...this.state.importPeopleData, rows: results
                                },
                                importPeopleRowCount: results.length
                            });

                            let duplicates = response.data.filter((data) => data.is_duplicate);
                            if (duplicates && duplicates.length > 0)
                                this.setState({
                                    showDuplicatePeople: true,
                                    duplicatesData: duplicates

                                });

                        },
                            (err) => {
                                console.log("Gift Service error for import people: " + err);
                            });

                }
            }

        }
    }

    toggleDuplicatePeople = () => {
        this.setState({ showDuplicatePeople: !this.state.showDuplicatePeople });
    }

    importPeople = (importData) => {

        var results = [];
        if(this.state.selectedTab === 'eventsTab')
        results = this.state.rows;
        else if(this.state.selectedTab === 'giftsTab')
        results = this.state.giftRecipients;
        var importRes = importData.map((item) => {
            return {
                person_id: item.person_id,
                first_name: item.first_name,
                last_name: item.last_name,
                display_name: item.display_name,
                dob: item.dob,
                is_card_signer: item.is_card_signer,
                category_id: item.category_id,
                category_name: item.category_name,
                company_id: item.company_id,
                company_name: item.company_name,
                division_id: item.division_id,
                division_name: item.division_name,
                title_id: item.title_id,
                title_name: item.title_name,
                address: item.address,
                address_type: item.address_type,
                phone: item.phone,
                email: item.email,
                guests: []
            }
        });

        console.log("Results -->" + results);
        console.log("Import Results -->" + importRes);

        var finalResult = results.concat(importRes.filter( ({person_id}) => !results.find(p => p.person_id == person_id)));
        if(this.state.selectedTab === 'eventsTab')
        this.setState({rows : finalResult});
        else if(this.state.selectedTab === 'giftsTab')
        this.setState({giftRecipients : finalResult});
        
        //this.togglePreviewImport();
        this.setState({
            showImportPeople: false,
            showDuplicatePeople: false,
            showPreviewImport: false,
            duplicatesdata: [],
            selectedFile: undefined,
            importPeopleData:  {...this.state.importPeopleData, rows: [] },
            importPeopleRowCount: 0
        });

    }

    finishDuplicateCheck = (finalResults) => {
        var res = finalResults.map((row) => ({ ...row, id: row.excel_row_no }));
        this.setState({importPeopleData :  {...this.state.importPeopleData, rows: res}});

    }

    togglePreviewImport = () => {
        this.setState({ showPreviewImport: !this.state.showPreviewImport });
    }

    */
    onGiftsChange = () => {
    this.props.onGiftsChange();
    }
    editCallbackFromGrid = (row) => {
        this.props.editCallbackFromGrid(row);
    }
    addInviteeFromSearch = (newValue) => {
        this.props.addInviteeFromSearch(newValue, this.state.selectedTab);
    }
    calculatePrice = () => {
        this.props.calculatePrice();
    }
    updateVendorList = (row) => {
        this.props.updateVendorList(row)
    }
    editInviteeSearch = (newValue) => {
        this.props.editInviteeSearch(newValue, this.state.selectedTab);
    }
    removeInviteFromSearch = (newValue) => {
        this.props.removeInviteFromSearch(newValue,this.state.selectedTab);
    }
    deleteAllInvitees = () => {
        this.setState({ rows: [] });
        this.props.deleteAllInvitees(this.state.selectedTab);
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: nextProps.data, giftSenders: nextProps.giftSenders,
            giftRecipients: nextProps.giftRecipients,
            giftSenders: nextProps.giftSenders,
            gifters: nextProps.gifters,
            recipientToAddBySearch: nextProps.recipientToAddBySearch,
            vendorsList: nextProps.vendorsList,
            giftMessage: nextProps.giftMessage,
            gridLoader: nextProps.gridLoader
        });
    }
    addPeople = (people) => {
        if (this.state.fromPage == 'event') {
            people.map((item) => { item['RSVP_status'] = null })
            this.props.addPeople(people);
        } else { this.props.addPeople(people, this.state.selectedTab); }
        this.toggleLookupPeople();
    }
    deleteAllGifts = () => {
        this.props.deleteAllGifts()
    }
    toggleMessageModal = () => {
        this.setState({
            messageModal: !this.state.messageModal,
        })
    };
    render() {
        return (
            <>
                <MDBCard id="eventCard" className='mb-3' style={{ height: '100%' }} disabled={this.state.gridLoader}>
                    <div className="flexBox">
                        <MDBTabs bgcolor="white" color='black' className='card-header-tabs'>
                            {this.props.fromPage == 'event' ? (
                                <MDBTabsItem>
                                    <MDBTabsLink color="white" onClick={this.handleTabClick.bind(this, 'eventsTab')}
                                        active={this.state.selectedTab === 'eventsTab'} >
                                        Invitees
                                </MDBTabsLink>
                                </MDBTabsItem>
                            ) : <>
                             <MDBTabsItem>
                                    <MDBTabsLink color="white" onClick={this.handleTabClick.bind(this, 'recipientsTab')}
                                        active={this.state.selectedTab === 'recipientsTab'} >
                                        Recipients
                                </MDBTabsLink>
                                </MDBTabsItem>
                                <MDBTabsItem>
                                    <MDBTabsLink color="white" onClick={this.handleTabClick.bind(this, 'sendersTab')}
                                        active={this.state.selectedTab === 'sendersTab'} >
                                        Senders
                                </MDBTabsLink>
                                </MDBTabsItem>
                                <MDBTabsItem>
                                    <MDBTabsLink color="white" onClick={this.handleTabClick.bind(this, 'giftsTab')}
                                        active={this.state.selectedTab === 'giftsTab'} >
                                        Gifts
                                </MDBTabsLink>
                                </MDBTabsItem>
                            </>
                            }

                        </MDBTabs>
                        {this.state.selectedTab != 'giftsTab' ? <>
                            <div className='lnkexpPanel'>
                                <Link href="#" id="expansionPanel"
                                    onClick={(event, newValue) => {
                                        event.preventDefault();
                                        this.setState({
                                            gridOpen: true,
                                            gridCollapse: false
                                        })
                                    }}
                                    color="primary" >
                                    expand all
                            </Link>
                                <Link href="#" id="expansionPanel"
                                    onClick={(event, newValue) => {
                                        event.preventDefault();
                                        this.setState({
                                            gridOpen: false,
                                            gridCollapse: true
                                        })
                                    }} color="primary" >
                                    collapse all
                        </Link>
                            </div>

                        </> : null}
                        <div className="flexAlignRight" disabled={this.state.disabled}>
                            {this.state.selectedTab != 'giftsTab' ?
                                <>
                                    <MDBBtn color="primary"
                                        onClick={this.toggleImportPeople.bind(this)}>
                                        <MDBIcon style={{ fontSize: 15 }} icon="arrow-circle-down" />&nbsp;&nbsp;Import
                                        {/* <ArrowDownwardRoundedIcon style={{ fontSize: 20 }}/>&nbsp;&nbsp;Import */}
                                    </MDBBtn>
                            &nbsp;
                            <MDBBtn color="primary"
                                        onClick={this.toggleLookupPeople.bind(this)}>
                                        <AddCircleRoundedIcon style={{ fontSize: 20 }} />
                                        &nbsp;&nbsp;{this.getLookupButtonName()}
                                    </MDBBtn>
                                </>
                                : <>
                                    <MDBBtn color="primary"
                                        onClick={this.handleAddGuest.bind(this)}>&nbsp;&nbsp;Add Gift
                            </MDBBtn></>}
                        </div>

                    </div>
                    <Import showImportPeople={this.state.showImportPeople}
                        toggleImportPeople={this.toggleImportPeople.bind(this)}
                        data={this.state.fromPage === 'event' ? this.props.data :
                            (this.state.fromPage === 'gift' ? (this.state.selectedTab == 'recipientsTab' ?
                                this.props.giftRecipients : this.state.giftSenders) : [])}
                        updateData={this.updateData.bind(this)}
                    />
                    <LookupModal id="lookup-people" isOpen={this.state.showLookupPeople} addPeople={this.addPeople}
                        pageName={this.state.pageName}
                        toggleLookupPeople={this.toggleLookupPeople.bind(this)}>
                    </LookupModal >

                    <MDBTabsContent>
                        {(this.props.fromPage == 'event' && this.state.selectedTab === 'eventsTab') ? (
                            <MDBTabsPane show={this.state.selectedTab === 'eventsTab'}>
                                <br />
                                <GridTable data={this.state.rows} selectedTab={this.state.selectedTab}
                                    recipientToAddBySearch={this.state.recipientToAddBySearch} masterId={this.state.masterId}
                                    addInviteeFromSearch={this.addInviteeFromSearch}
                                    removeInviteFromSearch={this.removeInviteFromSearch}
                                    editInviteeSearch={this.editInviteeSearch} editCallbackFromGrid={this.editCallbackFromGrid}
                                    deleteAllInvitees={this.deleteAllInvitees} disabled={this.state.disabled}
                                    page={this.state.page} rowsPerPage={this.state.rowsPerPage}
                                    gridOpen={this.state.gridOpen} gridCollapse={this.state.gridCollapse}></GridTable>
                            </MDBTabsPane>)
                            :
                            (<>
                                <MDBTabsPane show={this.state.selectedTab === 'recipientsTab'}>
                                    <br />
                                    <GridTable data={this.state.giftRecipients}
                                        recipientToAddBySearch={this.state.recipientToAddBySearch}
                                        selectedTab={this.state.selectedTab}
                                        addInviteeFromSearch={this.addInviteeFromSearch}
                                        removeInviteFromSearch={this.removeInviteFromSearch} disabled={this.state.disabled}
                                        editInviteeSearch={this.editInviteeSearch} editCallbackFromGrid={this.editCallbackFromGrid}
                                        deleteAllInvitees={this.deleteAllInvitees} page={this.state.page} rowsPerPage={this.state.rowsPerPage}
                                        gridOpen={this.state.gridOpen} gridCollapse={this.state.gridCollapse}></GridTable>
                                </MDBTabsPane>
                                <MDBTabsPane show={this.state.selectedTab === 'sendersTab'}>
                                    <br />
                                    <GridTable data={this.state.giftSenders}
                                        selectedTab={this.state.selectedTab}
                                        recipientToAddBySearch={this.state.recipientToAddBySearch}
                                        addInviteeFromSearch={this.addInviteeFromSearch}
                                        removeInviteFromSearch={this.removeInviteFromSearch} disabled={this.state.disabled}
                                        editInviteeSearch={this.editInviteeSearch} editCallbackFromGrid={this.editCallbackFromGrid}
                                        deleteAllInvitees={this.deleteAllInvitees} page={this.state.page} rowsPerPage={this.state.rowsPerPage}
                                        gridOpen={this.state.gridOpen} gridCollapse={this.state.gridCollapse}></GridTable>
                                </MDBTabsPane>
                                <MDBTabsPane show={this.state.selectedTab === 'giftsTab'}>
                                    <br />
                                    <GiftsGridTable giftsGridData={this.state.gifters ? this.state.gifters : []} disabled={this.state.disabled}
                                        giftRecipients={this.state.giftRecipients} selectedTab={this.state.selectedTab}
                                        gridOpen={this.state.gridOpen} gridCollapse={this.state.gridCollapse}
                                        calculatePrice={this.calculatePrice} deleteAllGifts={this.deleteAllGifts}
                                        vendorsList={this.state.vendorsList} updateVendorList={this.updateVendorList}
                                        onGiftsChange={this.onGiftsChange}></GiftsGridTable>
                                </MDBTabsPane>
                            </>)}
                    </MDBTabsContent>
                </MDBCard>

                {/* <MDBModal isOpen={this.state.messageModal} toggle={this.toggleMessageModal} >
                    <MDBModalHeader
                        className="text-left"
                        titleClass="w-100 font-weight-bold"
                        toggle={this.toggleMessageModal}>
                        Gift Message
                        </MDBModalHeader>
                    <MDBModalBody >
                        <textarea class="textareabox"
                            id="eventAddress" rows="10" cols='5'
                            placeholder='Gift Message' value={this.state.giftMessage} onChange={(event) => {
                                this.setState({ giftMessage: event.target.value })
                            }}
                        //value={this.state.distributionAddress}
                        ></textarea>
                    </MDBModalBody>
                    <MDBModalFooter className="justify-content-center">
                        <button type="button" onClick={() => {
                            this.toggleMessageModal();
                            this.props.updateGiftMessage(this.state.giftMessage);
                            // this.setGiftMessage(this.state.recordIndex, this.state.giftMessage);
                        }}>
                            Add
                            </button>
                        <button type="button" class='primary' onClick={() => {
                            this.toggleMessageModal();
                        }}>
                            Close
                            </button>
                    </MDBModalFooter>
                </MDBModal>
 */}
            </>
        );
    }
}

export default Grid;