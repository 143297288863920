export const GifterServiceBaseUrl = 'https://api.gifter.warnerbros.com'; 
export const GifterSharedServiceBaseUrl = ''; 
export const OKTA_CLIENT_ID = "0oasegmly2QO0GR2o0x7";
export const OKTA_ISSUER_URL = "https://tw.okta.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://tw.okta.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";


