import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline } from "mdbreact";
import '../AttendenceManagement/AttendanceManagement.scss';
import { MDBBtn } from 'mdb-react-ui-kit';
import AttendenceManagementMD from '../AttendenceManagement/AttendanceManagementMd';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormatListBulletedRounded } from '@material-ui/icons';
import moment from 'moment';

class AttendenceManagement extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            eventId: props?.match?.params?.eventId,
            datatable: [],
            loaderOpen: false,
            countDetails: {
                checked_in_count: 0, guest_count: 0,
                invitee_count: 0, rsvp_accepted_count: 0,
                rsvp_declined_count: 0, rsvp_no_response_count: 0
            },
            isSortByAsc: true,
            sortByIcon: 'sort-alpha-down',
            page: 0,
            rowsPerPage: 10,
            saveProgress: false,
            activePersons: [],
            titles: [],
            eventHeader: null,
            disabled: props?.match?.path.indexOf('viewatdMngmt') != -1 ? true : false,
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loaderOpen: true
        })
        this.fetchData();
    }
    reload = () => {
        this.fetchData();
    }
    fetchData = async () => {
        let eventRecordId = this.state.eventId;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetEventAttendanceDetails = Constants.GifterServiceBaseUrl +
            '/eventAttendanceDetails?eventRecordId=' + eventRecordId;
        let GetTitles = Constants.GifterServiceBaseUrl + '/titles?tenantId=' + tenantId;
        let GetEventRecordHeader = Constants.GifterServiceBaseUrl +
            '/eventHeader?eventRecordId=' + eventRecordId;
        let urls = [];
        urls = [GetEventAttendanceDetails, GetTitles, GetEventRecordHeader]
        try {
            const res = await Promise.all(urls.map(url =>
                GifterService.getData(url)));
            const data = res.map((res) => res.data);
            if (data && data.length > 0) {
                let countDetails = this.state.countDetails;
                countDetails.checked_in_count = data[0][0]?.checked_in_count;
                countDetails.guest_count = data[0][0]?.guest_count;
                countDetails.invitee_count = data[0][0]?.invitee_count;
                countDetails.rsvp_accepted_count = data[0][0]?.rsvp_accepted_count;
                countDetails.rsvp_declined_count = data[0][0]?.rsvp_declined_count;
                countDetails.rsvp_no_response_count = data[0][0]?.rsvp_no_response_count;
                if (data[0] && data[0].length > 0) {
                    data[0].forEach((item) => {
                        item['name'] = item.first_name + ' ' + item.last_name
                    })
                    data[0].sort((a, b) => (a.name.toString() > b.name.toString()) ? 1 : -1);
                }
                this.setState({
                    datatable: data[0],
                    countDetails: countDetails,
                    activePersons: [],
                    titles: data[1],
                    eventHeader: data[2]
                }, () => {
                    this.setState({
                        loaderOpen: false,
                        saveProgress: false
                    })
                });
            }
        } catch {
            throw Error("Promise failed");
            this.setState({
                saveProgress: false
            })
        }
    };
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    }
    rsvpStatusChange = (event, row, flag = 0) => {
        this.setState({ saveProgress: true });
        let invitees = this.state.datatable;
        let eventObj = {
            "gift_event_card_id": row.gift_event_card_id,
            "event_invitee_id": row.event_invitee_id,
            "rsvp_status": flag

        }
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventInviteeRSVPUpdate', eventObj)
            .then((response) => {
                this.fetchData();
            },
                (err) => {
                    console.log("People Management grid service error: " + err);
                    this.setState({ saveProgress: false });
                });
    }
    checkinStatusChange = (event, row, flag = 0) => {
        this.setState({ saveProgress: true });
        let eventObj = {
            "gift_event_card_id": row.gift_event_card_id,
            "event_invitee_id": row.event_invitee_id,
            "checked_in_status": flag

        }
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventInviteeCheckedIn', eventObj)
            .then((response) => {
                this.fetchData();
            },
                (err) => {
                    this.setState({ saveProgress: false });
                    console.log("People Management grid service error: " + err);
                });
    }
    handleTableSort = (sortBy) => {
        let isSortByAsc = this.state.isSortByAsc;
        let sortByIcon = !isSortByAsc ? 'sort-alpha-down' : 'sort-alpha-up';
        let records = this.state.datatable;
        if (isSortByAsc) {
            records = records?.sort((a, b) => (b.name.toString() > a.name.toString()) ? 1 : -1);
        } else {
            records = records?.sort((a, b) => (a.name.toString() > b.name.toString()) ? 1 : -1);
        }

        this.setState({ isSortByAsc: !isSortByAsc, sortByIcon: sortByIcon, datatable: records, page: 0 })


    }

    render() {
        let r = this.state.datatable ? this.state.datatable : [];
        let records = r?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (
            <>
                {this.state.loaderOpen ? (<>
                    <div className="tableLoader">
                        <Backdrop className='backdrop' open={this.state.loaderOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </>) : (<>
                    <div className="d-none d-lg-block">
                        <div>
                            <div class="row">
                                <div class="col-6" id='atdeventDetails'>
                                    <Link href="#"
                                        onClick={(event, newValue) => {
                                            event.preventDefault();
                                        }}
                                        color="primary" >
                                        <MDBIcon icon="arrow-left" className="ml-1" style={{ fontSize: 20 }} onClick={(event, newValue) => {
                                            event.preventDefault();
                                            if (this.state.disabled) {
                                                this.props.history.push('/viewevent/' + this.state.eventId)
                                            } else
                                                this.props.history.push('/event/' + this.state.eventId + '/0')
                                        }} />
                                    </Link>
                                    <label className='mainLabel'>Attendance Management</label>
                                    {/* <span>&nbsp;&nbsp;Event Details</span> */}
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class="col-md-5 col-lg-5" id='atdeventDetails'>
                                <span class='mdTitle ML2P2'>{this.state.eventHeader?.[0]?.name}</span>
                                <br />
                                <span class='colorGray ML2P2'>{this.state.eventHeader?.[0]?.event_date != null ?
                                    moment(this.state.eventHeader?.[0]?.event_date).format('MMM. DD, YYYY') + '-' +
                                    this.state.eventHeader?.[0]?.event_time + ' ' + 'PDT' : ''}</span>
                                <br />
                                <span class='colorGray ML2P2'>{this.state.eventHeader?.[0]?.event_address}</span>
                                <br />
                            </div>
                            <div class='col-lg-7 col-md-8'>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <lable className='blueNumberLable'>{this.state.countDetails.guest_count ?
                                            this.state.countDetails.guest_count : 0}</lable>
                                        <br />
                                        <label className='blueTextLable'>Total Guests</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <lable className='blueNumberLable'>{this.state.countDetails.invitee_count ? this.state.countDetails.invitee_count : 0}</lable>
                                        <br />
                                        <label className='blueTextLable'>Invitees</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <lable className='greenNumberLable'>{this.state.countDetails.rsvp_accepted_count ? this.state.countDetails.rsvp_accepted_count : 0}</lable>
                                        <br />
                                        <label className='greenTextLable'>Confirmed</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <lable className='redNumberLable'>{this.state.countDetails.rsvp_declined_count ? this.state.countDetails.rsvp_declined_count : 0}</lable>
                                        <br />
                                        <label className='redTextLable'>Declined</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <lable className='grayNumberLable'>{this.state.countDetails.rsvp_no_response_count ? this.state.countDetails.rsvp_no_response_count : 0}</lable>
                                        <br />
                                        <label className='grayTextLable'>No Reply</label>
                                    </div>
                                    {/* <div class="col-lg-2">
                                        <lable className='redNumberLable'> {this.state.countDetails.rsvp_declined_count ? this.state.countDetails.rsvp_declined_count : 0}<span className='redTextLable'>Declined</span></lable>
                                        <br />
                                        <label className='grayNumberLable'>{this.state.countDetails.rsvp_no_response_count ? this.state.countDetails.rsvp_no_response_count : 0} <span className='grayTextLable'>No Reply</span></label>
                                    </div> */}
                                    <div class="col-lg-2">
                                        <lable className='blueNumberLable'>{this.state.countDetails.checked_in_count ? this.state.countDetails.checked_in_count : 0}</lable>
                                        <br />
                                        <label className='blueTextLable'>Checked-In</label>
                                    </div>
                                </div>

                            </div>

                            <div class='row'>
                                {this.state.saveProgress ? (<>
                                    <LinearProgress /></>) : null}
                            </div>
                        </div>
                        <TableContainer component={Paper} disabled={this.state.disabled}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow className='tblHead'>
                                        <TableCell className='tblHead'>RSVP</TableCell>
                                        <TableCell className='tblHead'>Attendance</TableCell>
                                        <TableCell className='tblHead' onClick={() => this.handleTableSort()}>
                                            <div>Invitee<MDBIcon icon={this.state.sortByIcon} /></div></TableCell>
                                        <TableCell className='tblHead'>Guests</TableCell>
                                        <TableCell className='tblHead' colSpan={2}>Additional Information</TableCell>
                                        <TableCell className='tblHead'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {records?.map((row, index) => (
                                        <TableRow key={row.person_id}>
                                            <TableCell component="th" scope="row" className='W17'>
                                                <MDBIcon icon="check-circle" className="ml-1" style={{
                                                    color: row.RSVP_status != null && row.RSVP_status == true ? "green" : "gray",
                                                    fontSize: 16
                                                }} onClick={(event, newValue) => {
                                                    event.preventDefault();
                                                    this.rsvpStatusChange(event, row, row.RSVP_status != null ? null : 1)
                                                }} />
                                        &nbsp;&nbsp;
                                        <MDBIcon icon="times-circle" className="ml-1" style={{
                                                    color: row.RSVP_status != null && row.RSVP_status == false ? "red" : "gray",
                                                    fontSize: 16
                                                }} onClick={(event, newValue) => {
                                                    event.preventDefault();
                                                    this.rsvpStatusChange(event, row, row.RSVP_status != null ? null : 0)
                                                }} />
                                        &nbsp;&nbsp;
                                        {row.RSVP_status == null ? <label className='lblNoreply'>No Reply</label> :
                                                    (row.RSVP_status ? <label className='lblConfirmed'>Confirmed</label>
                                                        : <label className='lblDenied'>Declined</label>)}
                                            </TableCell>
                                            <TableCell className='F12 W14'>
                                                {row.checked_in_status ? <label className='lblcheckin' onClick={(event, newValue) => {
                                                    event.preventDefault();
                                                    this.checkinStatusChange(event, row, 0)
                                                }}>Checked-In</label>
                                                    : <MDBBtn className='L05' color="primary" size='sm' onClick={(event, newValue) => {
                                                        event.preventDefault();
                                                        this.checkinStatusChange(event, row, 1)
                                                    }}>Check-In</MDBBtn>}
                                            </TableCell>

                                            <TableCell className='F12 W14'>{row.name}
                                                {/* {row.first_name + ' ' + row.last_name} */}
                                            </TableCell>
                                            <TableCell className='W27 F12'>
                                                {/* <label>{row.guest_name}</label> */}
                                                <label>{Array.prototype.map.call(row.guests, function (item) { return item.guest_name }).join(",")}</label>

                                            </TableCell>
                                            <TableCell className='F12 W10'>
                                                {row.title_name}
                                            </TableCell>

                                            <TableCell className='F12 W16'>
                                                {row.phone}
                                            </TableCell>

                                            <TableCell className='F12 W14'>
                                                {row.email}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={this.state.datatable.length}
                            rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                    <div className="d-block d-lg-none">
                        <AttendenceManagementMD rows={this.state.datatable} page={this.state.page} rowsPerPage={this.state.rowsPerPage}
                            saveProgress={this.state.saveProgress}
                            countDetails={this.state.countDetails}
                            rsvpStatusChange={this.rsvpStatusChange}
                            checkinStatusChange={this.checkinStatusChange}
                            activePersons={this.state.activePersons} disabled={this.state.disabled}
                            titles={this.state.titles} eventId={this.state.eventId} eventHeader={this.state.eventHeader} reload={this.reload}
                        ></AttendenceManagementMD>
                    </div>
                </>)}
            </>
        );
    }
}

export default AttendenceManagement;
