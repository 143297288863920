import React, { useState, useEffect, useCallback, useMemo } from "react";
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBInput, MDBFormInline } from "mdbreact";
import { useHistory, withRouter } from "react-router-dom";
import {
    MDBCard,
    MDBCardBody,
    MDBSelect,
    MDBBtn,
} from "mdb-react-ui-kit";
import TextField from "@material-ui/core/TextField";
import Grid from "../Grid/Grid";
import "../EventCreation/CreateEvent.scss";
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Materials from '../Materials/Materials';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Button from '@material-ui/core/Button';
import { Prompt } from 'react-router-dom';

const format = 'h:mm A';
const now = moment().hour(0).minute(0);

class CreateEventPage extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            eventId: props?.match?.params?.eventId,
            disabled: props?.match?.path.indexOf('viewevent') != -1 ? true : false,
            divisions: null,
            shows: null,
            eventName: '',
            eventInvitees: null,
            existingEventTitles: null,
            recipientToAddBySearch: null,
            eventDate: '',
            eventTime: '',
            eventAddress: '',
            eventInvAddress: '',
            isSingleInvAddress: false,
            selectedTab: props.defaultTab,
            gridOpen: false,
            fromPage: props.fromPage,
            giftsGridData: props.giftsGridData,
            loaderOpen: false,
            showMaterials: false,
            formErrors: { eventName: '', Division: '', DateField: '', TimeField: '', eventAddress: '', Address: '' },
            formValid: false,
            eventNameValid: false,
            divisionValid: false,
            eventDateValid: false,
            eventTimeValid: false,
            eventAddressValid: false,
            isBlocked: false,
            deleteAll: false,
            isCopy: props?.match?.params?.copyId == 1 ? true : false
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loaderOpen: true
        })
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        var prevEventId = prevProps?.match?.params?.eventId;
        var currEventId = this.props?.match?.params?.eventId;
        if (prevEventId != currEventId && currEventId == 0) {
            this.setState({
                eventId: 0,
                // divisions: null,
                // shows: null,
                eventName: '',
                eventInvitees: null,
                // existingEventTitles: null,
                // recipientToAddBySearch: null,
                eventDate: '',
                eventTime: '',
                eventAddress: '',
                eventInvAddress: '',
                isSingleInvAddress: false,
                selectedTab: this.props.defaultTab,
                gridOpen: false,
                fromPage: this.props.fromPage,
                // giftsGridData: this.props.giftsGridData,
                loaderOpen: false,
                showMaterials: false,
                formErrors: { eventName: '', Division: '', DateField: '', TimeField: '', eventAddress: '', Address: '' },
                formValid: false,
                eventNameValid: false,
                divisionValid: false,
                eventDateValid: false,
                eventTimeValid: false,
                eventAddressValid: false
            });
            this.fetchData(0);
        }
    }

    fetchData = async (eventId) => {
        let eventRecordId = (eventId != 0 ? this.state.eventId : 0);// == 0 ? 0 : 16384;
        let eventNameValid = eventRecordId > 0 ? true : false;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetEventRecordHeader = Constants.GifterServiceBaseUrl +
            '/eventHeader?eventRecordId=' + eventRecordId;
        let GetEventRecordNames = Constants.GifterServiceBaseUrl + '/eventNames?tenantId=' + tenantId;
        let GetEventRecordInvitees = Constants.GifterServiceBaseUrl + '/eventInvitees?eventRecordId=' + eventRecordId;
        let GetLogginUsers = Constants.GifterServiceBaseUrl + '/loggedInUser'
        let urls = [];
        let eventDate = null;
        let eventTime = null;
        let evtAddress = '';
        let invtAddress = '';
        let isSingleAddress = false;
        let divs = null;
        let shows = null;
        let divisionValid = false;
        let eventDateValid = false;
        let eventTimeValid = false;
        let eventAddressValid = false;
        urls = [GetLogginUsers, GetEventRecordNames, GetEventRecordHeader, GetEventRecordInvitees]
        try {
            const res = await Promise.all(urls.map(url =>
                GifterService.getData(url)));
            const data = res.map((res) => res.data);
            if (data && data.length > 0) {
                let accessmodules = data[0][0].tenants.filter(item => item.tenant_id == tenantId)[0].access_roles.filter(res => res.module_name == 'Events')[0];
                divs = accessmodules.divisions;
                shows = accessmodules.shows;
                divs.map(obj => ({ ...obj, selected: false }))
                shows.map(obj => ({ ...obj, selected: false }))
                if (data[2] && data[2].length > 0) {
                    accessmodules.divisions.forEach((division) => {
                        if (division.org_unit_id == data[2][0].org_unit_id) {
                            division['selected'] = true;
                            divisionValid = true;
                        }
                    })
                    accessmodules.shows.forEach((show) => {
                        if (show.show_id == data[2][0].show_id) {
                            show['selected'] = true;
                        }
                    })
                    if (data[2][0].event_date) {
                        eventDateValid = true;
                        //let date = new Date(data[2][0].event_date);
                        let date = new Date(data[2][0].event_date.replace(/-/g, '\/').replace(/T.+/, ''));
                        eventDate = date.getFullYear() + '-' +
                            ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
                            + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
                    }
                    eventTime = data[2][0].event_time ? data[2][0].event_time : '';
                    eventTimeValid = eventTime != '' ? true : false
                    //eventTimeValid = false;
                    evtAddress = data[2][0].event_address;
                    eventAddressValid = evtAddress && evtAddress != '' ? true : false
                    invtAddress = data[2][0].event_invitation_address;
                    isSingleAddress = data[2][0].event_is_default_all_invitees;
                }
                this.setState({
                    divisions: divs, shows: shows,
                    existingEventTitles: data[1], eventName: data[2].length > 0 ? data[2][0].name : '',
                    eventInvitees: data[3]
                    , recipientToAddBySearch: [],// data[4],
                    eventDate: eventDate,
                    eventAddress: evtAddress, eventInvAddress: invtAddress, isSingleInvAddress: isSingleAddress,
                    divisionValid: divisionValid, eventNameValid: eventNameValid, eventDateValid: eventDateValid,
                    eventTimeValid: eventTimeValid, eventTime: eventTime
                    , eventAddressValid: eventAddressValid
                }, () => {
                    this.setState({
                        loaderOpen: false
                    }, () => {
                        this.validateForm();
                        if (this.state.isCopy) {
                            this.copyEvent()
                        }
                    })
                });
            }
        } catch {
            throw Error("Promise failed");
        }
    };
    addInviteeFromSearch = (newValue, tab = '') => {
        let invitees = this.state.eventInvitees;
        let person_id = newValue.person_id;
        let record = invitees.filter(item => item.person_id == person_id);

        let existingRecord = record && record.length > 0;
        if (!existingRecord) {
            newValue['event_invitee_id'] = null;
            newValue['guests'] = [];
            newValue['RSVP_status'] = null;
            let invitees = this.state.eventInvitees;
            invitees.splice(0, 0, newValue);
            let filteredList = this.mergeDuplicates(invitees);
            this.setState({
                eventInvitees: filteredList,
                isBlocked: true
            }, () => { this.validateForm() });
        }
    }
    removeInviteFromSearch = (newValue, tab = '') => {
        let invitees = this.state.eventInvitees;
        if (newValue.person_id) {
            invitees = invitees.filter(item => item?.person_id != newValue.person_id)
        }
        if (newValue.event_invitee_id) {
            invitees = invitees.filter(item => item?.event_invitee_id != newValue.event_invitee_id)
        }
        this.setState({
            eventInvitees: invitees,
            isBlocked: true
        }, () => { this.validateForm() });
    }
    deleteAllInvitees = () => {
        this.setState({
            eventInvitees: [],
            isBlocked: true,
            deleteAll: true
        }, () => { this.validateForm() });
    }
    setImportState = (rows) => {
        let eventInvitees = this.state.eventInvitees;
        // rows.map(obj => ({ ...obj, event_invitee_id: null }))
        rows.map(obj => ({ ...obj, guests: [] }))
        rows.map(obj => (
            // obj['event_invitee_id'] = null,
            obj['guests'] = [],
            eventInvitees.push(obj)
        ))
        let filteredList = this.mergeDuplicates(eventInvitees);
        this.setState({
            eventInvitees: filteredList,
            isBlocked: true
        }, () => { this.validateForm() })
    }
    _handleTextFieldChange = (event, newValue) => {
        let r = event.target.value;
        this.setState({
            eventDate: event.target.value,
            isBlocked: true
        }, () => { this.validateField('eventDate', r) });
    };
    handleTimeChange = value => {
        console.log(value && value.format('HH:mm:ss'));
        this.setState({ eventTime: value.format(format), isBlocked: true }, () => { this.validateField('eventTime', value.format(format)) });
    };
    _divisionChanges = (event) => {
        if (event.target.value != '0') {
            let divisionId = event.target.value;
            let divisions = this.state.divisions;
            divisions.map(obj => ({ ...obj, selected: false }))
            divisions.forEach((division) => {
                if (division.org_unit_id == divisionId) {
                    division['selected'] = true;
                }
            })
            this.setState({
                divisions: divisions,
                isBlocked: true
            }, () => { this.validateField('eventDivision', '1') });
        } else { this.validateField('eventDivision', 0) }
    }
    editCallbackFromGrid = (row) => {
        this.setState({
            eventInvitees: row,
            isBlocked: true
        }, this.validateForm())
    }

    handleMaterials = () => {
        this.setState({ showMaterials: !this.state.showMaterials });
    }

    _showsChanges = (event, newValue) => {
        if (event.target.value != '0') {
            let showId = event.target.value;
            let shows = this.state.shows;
            shows.map(obj => ({ ...obj, selected: false }))
            shows.forEach((show) => {
                if (show.show_id == showId) {
                    show['selected'] = true;
                }
            })
            this.setState({
                shows: shows,
                isBlocked: true
            });
        };
    };
    eventNameUpdate = (titleName) =>{
        this.setState({
            eventName: titleName,
            isBlocked: true
        });
        this.validateField('eventName', titleName)
    }
    _handleEventNameChange = (event) => {
        // let existingTitles = this.state.existingEventTitles;
        //let isAdd = this.state.eventId > 0 ? false : true;
        let titleName = event.target.value ? event.target.value : '';
        _.debounce(this.eventNameUpdate(titleName), 50);
        // }, () => { this.validateField('eventName', titleName) })
        // if (isAdd) {
        //     let match = existingTitles.filter((item) => item.name == titleName);
        //     if (match && match.length > 0) {
        //         alert('Title Already Exists in the System');
        //     }
        // }
    };
    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };
    copyEvent = () => {
        this.setState({ eventId: 0, eventName:""})
    }

    saveEvent = () => {
        this.setState({ formValid: false })
        let invitees = this.state.eventInvitees && this.state.eventInvitees.length > 0 ? this.state.eventInvitees : [];
        if (this.state.eventId == 0) {
            invitees.map(item => {
                item['gift_event_card_id'] = null;
                item['event_invitee_id'] = null;
            });
        }
        let eventObj = {
            "gift_event_card_id": this.state.eventId > 0 ? Number(this.state.eventId) : null,
            "name": this.state.eventName,
            "org_unit_id": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_id,
            "org_unit_name": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_name,
            "show_id": this.state.shows.filter(item => item.selected == true).length > 0 ? this.state.shows.filter(item => item.selected == true)?.[0].show_id : null,
            "show_name": this.state.shows.filter(item => item.selected == true).length > 0 ? this.state.shows.filter(item => item.selected == true)?.[0].show_name : null,
            "event_location": null,
            "event_date": this.state.eventDate ? this.state.eventDate : null,
            "event_time": this.state.eventTime ? this.state.eventTime : '',
            "event_address": this.state.eventAddress,
            "event_is_default_all_invitees": this.state.isSingleInvAddress && this.state.isSingleInvAddress == 1 ? true : false,
            "event_invitation_address": this.state.eventInvAddress && this.state.eventInvAddress != '' ? this.state.eventInvAddress : null,
            // "event_invitees": this.state.eventInvitees && this.state.eventInvitees.length > 0 ? this.state.eventInvitees : [],
            "event_invitees": invitees,
            "is_delete_all_invitees": this.state.deleteAll
        }
        console.log("eventObject", eventObj)
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventRecord', eventObj)
            .then((response) => {
                NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));

                let orgId = this.state.eventId;
                if (response.data.length > 0) {
                    let res = response?.data?.[0].gift_event_card_id;
                    if (orgId > 0) {
                        NotificationManager.info('Updated Successfully', 'Event');
                        setTimeout(() => {
                            this.setState({ eventId: res, loaderOpen: true, isBlocked: false, deleteAll: false });
                            this.fetchData();
                        }, 1500);

                    } else {
                        NotificationManager.info('Created Successfully', 'Event');
                        setTimeout(() => {
                            this.setState({ eventId: res, loaderOpen: true, isBlocked: false, deleteAll: false, isCopy: false });
                            this.props.history.push('/event/' + res + '/0');
                            this.fetchData();
                            //window.location.reload();
                        }, 1500);
                    }
                } else {
                    NotificationManager.error(response?.data?.message ? response?.data?.message : 'Error', 'Event');
                    this.setState({ formValid: true });
                }
            },
                (err) => {
                    this.setState({ formValid: true })
                    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                    NotificationManager.error('Save Failed', 'Event');
                    console.log("People Management grid service error: " + err);
                });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let eventNameValid = this.state.eventNameValid;
        let divisionValid = this.state.divisionValid;
        let eventDateValid = this.state.eventDateValid;
        let eventTimeValid = this.state.eventTimeValid;
        let eventAddressValid = this.state.eventAddressValid;

        switch (fieldName) {
            case 'eventName':
                eventNameValid = value != '';
                fieldValidationErrors.eventName = eventNameValid ? '' : ' is invalid';
                break;
            case 'eventDivision':
                divisionValid = value != '0';
                fieldValidationErrors.Division = divisionValid ? '' : ' is invalid';
                break;
            case 'eventDate':
                eventDateValid = value != null;
                fieldValidationErrors.DateField = eventDateValid ? '' : ' is invalid';
                break;
            case 'eventTime':
                eventTimeValid = value != null || value != '';
                fieldValidationErrors.TimeField = eventTimeValid ? '' : ' is invalid';
                break;
            case 'eventAddress':
                eventAddressValid = value != '';
                fieldValidationErrors.Address = eventAddressValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            eventNameValid: eventNameValid,
            divisionValid: divisionValid,
            eventDateValid: eventDateValid,
            eventTimeValid: eventTimeValid,
            eventAddressValid: eventAddressValid,
        }, this.validateForm());
    }
    validateForm() {
        let formValid = (this.state.eventNameValid || this.state.eventId > 0) && this.state.divisionValid &&
            this.state.eventDateValid && this.state.eventTimeValid &&
            this.state.eventAddressValid
        if (formValid && this.state.isSingleInvAddress) {
            formValid = this.state.eventInvAddress != null && this.state.eventInvAddress != '' ? true : false
        }
        formValid = formValid && this.state.eventInvitees.length > 0
        this.setState({
            formValid: formValid
        });
    }
    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    addPeople = (people) => {
        let invitees = this.state.eventInvitees;
        people.map(item => {
            invitees.push(item);
        });
        let filteredList = this.mergeDuplicates(invitees);
        this.setState({
            eventInvitees: filteredList,
            isBlocked: true
        }, () => { this.validateForm() });
    }

    createNotification = (type, message, Title) => {
        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info(message, Title);
                    break;
                case 'success':
                    NotificationManager.success(message, Title);
                    break;
                case 'warning':
                    NotificationManager.warning(message, 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error(message, 'Click me!', 5000, () => {
                    });
                    break;
            }

        };
    };
    mergeDuplicates = (data) => {
        let filteredArr = data.reduce((acc, current) => {
            const x = acc.find(item => item.person_id === current.person_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        return filteredArr
    }

    updatePeopleFromImport = (finalResult) => {
        this.setState({
            eventInvitees: finalResult,
            isBlocked: true
        });
    }
    eventAddressChange = _.debounce((id, val) => this.setState({ eventAddress: val, isBlocked: true }, () => { this.validateField('eventAddress',val) }), 50);
    eventInvAddressChange = _.debounce((id,val) => this.setState({eventInvAddress: val, isBlocked: true}, () => { this.validateField('invAddress','')}), 50);
    render() {
        return (
            <>
                {this.state.loaderOpen ? (<>
                    <div className="tableLoader">
                        <Backdrop className='backdrop' open={this.state.loaderOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </>) : (<>
                    <div id="eventcreation" class="M1">
                        <Prompt when={this.state.isBlocked} message={"You have unsaved changes. Are you sure you want to leave this page?"} />
                        <form className="demoForm" onSubmit={this.submitHandler}>
                            <div class='row'>
                                <div className={`col-lg-4 col-md-4 F25 form-group ${this.errorClass(this.state.formErrors.eventName)}`} disabled={this.state.disabled}>
                                    {/* {this.state.eventId > 0 ? <label>{this.state.eventName}</label> : */}
                                    <MDBInput id="eventName" placeholder="Event Name.." type="text"
                                        className="form-control" required name="eventName" value={this.state.eventName}
                                        onChange={(event) => this._handleEventNameChange(event)}>
                                    </MDBInput>
                                </div>
                                <div
                                    class="col-lg-8 col-md-8"
                                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                                    id="btnRight"
                                >
                                    <Link component={RouterLink} to={this.state.disabled ? `/viewatdMngmt/${this.state.eventId}` : `/atdMngmt/${this.state.eventId}`}
                                        disabled={this.state.eventId > 0 ? false : true}>
                                        <MDBBtn color="primary" component={RouterLink} >
                                            <MDBIcon icon="calendar-check"
                                            /> &nbsp;&nbsp;Attendance
                                        </MDBBtn>
                                    </Link>
                                    <MDBBtn color="primary" onClick={this.handleMaterials.bind(this)}>
                                        <MDBIcon icon="file-image" className="ml-1" />&nbsp;&nbsp;Materials
                                        </MDBBtn>
                                    <MDBBtn type='button' color="primary" onClick={this.copyEvent}
                                        disabled={this.state.eventId == 0 || this.state.isCopy}>
                                        <MDBIcon icon="copy" className="mr-1" />&nbsp;&nbsp;Copy
                            </MDBBtn>

                                    <Materials isOpen={this.state.showMaterials} disabled={this.state.disabled}
                                        toggleMaterials={this.handleMaterials.bind(this)}
                                        fromPage="Event"
                                        recipients={this.state.eventInvitees}
                                        id={this.state.eventId}
                                        name={this.state.eventName}
                                        address={this.state.eventAddress}
                                    >

                                    </Materials>
                                    <MDBBtn color="primary" type="submit" onClick={this.saveEvent}
                                        disabled={!this.state.formValid || this.state.disabled || !this.state.isBlocked}>
                                        <MDBIcon icon="save" className="mr-1" />&nbsp;&nbsp;Save
                                    </MDBBtn>
                                </div>
                            </div>
                            <div class="row">
                                <MDBCard id="eventCard" className='mb-3' style={{ height: "100%" }} disabled={this.state.disabled}>
                                    <MDBCardBody>
                                        <MDBRow style={{ padding: "0.55rem 0 0.55rem 0" }} className='MB1'>
                                            <div className={`col-md-5 PL2 form-group ${this.errorClass(this.state.formErrors.Division)}`} id="evntDivision">
                                                <MDBContainer className="evntblock"></MDBContainer>
                                                <MDBRow className="evntblock">
                                                    <MDBCol className='MB1'>
                                                        <label>
                                                            <span className="redStar">*</span>Division:

                                                </label>
                                                        <select className="browser-default custom-select form-test-control" id='eventSelect' name='eventDivision'
                                                            onChange={this._divisionChanges.bind(this)}>
                                                            <option value='0'>Select</option>
                                                            {this.state.divisions ?
                                                                (this.state.divisions.map((row) => (
                                                                    <option value={row.org_unit_id}
                                                                        selected={row.selected}>{row.org_unit_name}</option>
                                                                ))) : null}
                                                        </select>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="evntblock">
                                                    <MDBCol className='MB1'>
                                                        <label>&nbsp;&nbsp;Show:</label>
                                                        <select class="browser-default custom-select" id='eventSelect' onChange={this._showsChanges.bind(this)}>
                                                            <option value='0'>Select</option>
                                                            {this.state.shows ?
                                                                (this.state.shows.map((row) => (
                                                                    <option value={row.show_id}
                                                                        selected={row.selected}>{row.show_name}</option>
                                                                ))) : null}
                                                        </select>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="evntblock">
                                                    <MDBCol id="eventDate" className={`form-group ${this.errorClass(this.state.formErrors.DateField)}`}>
                                                        <label className='PTN1'>
                                                            <span className="redStar">*</span>
                                                Date:
                                                </label>
                                                        <TextField className='form-test-control'
                                                            type="date" name='eventDate'
                                                            format="MM/dd/yyyy"
                                                            placeholder="MM/DD/YYYY"
                                                            variant="outlined"
                                                            onChange={this._handleTextFieldChange.bind(this)}
                                                            value={this.state.eventDate ? this.state.eventDate : ''}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="evntblock">
                                                    <MDBCol id="eventTime"
                                                        className={`form-group ${this.errorClass(this.state.formErrors.TimeField)}`}>
                                                        <label className='PTN1'>
                                                            <span className="redStar">*</span>
                                                Time:
                                                </label>

                                                        {/* <TextField className='form-test-control'
                                                            id="time" name='eventTime'
                                                            type="time"
                                                            variant="outlined"
                                                            defaultValue={this.state.eventTime}
                                                            onChange={e => {
                                                                let r = e.target.value
                                                                this.setState({ eventTime: r }
                                                                    , () => { this.validateField('eventTime', r) })
                                                            }}
                                                        /> */}
                                                        <TimePicker showSecond={false} format={format} use12Hours
                                                            value={this.state.eventTime && this.state.eventTime != '' ?
                                                                //    this.state.eventTime : ''}
                                                                moment(this.state.eventTime, 'HH:mm A') : ''}
                                                            onChange={this.handleTimeChange}
                                                        />
                                                        <label className="timepicker">(PDT)</label>

                                                    </MDBCol>
                                                </MDBRow>
                                            </div>

                                            <div
                                                class="col-lg-7 col-md-6 col-sm-6"
                                                id="evntDivision"
                                                style={{ float: "right" }}
                                            >
                                                <MDBContainer className="addressblock">
                                                    <MDBRow>
                                                        <MDBCol size="12"
                                                            className={`form-group ${this.errorClass(this.state.formErrors.Address)}`}>
                                                            <label>
                                                                <span className="redStar">*</span>Event Address:
                                                        </label>
                                                            <textarea
                                                                class="textareabox textAreaWidth form-test-control"
                                                                id="eventAddress"
                                                                rows="3"
                                                                defaultValue={this.state.eventAddress != null ? this.state.eventAddress : ''}
                                                                onChange = {e => this.eventAddressChange('eventAddress',e.target.value)}
                                                                /* onBlur={e => {
                                                                    let r = e.target.value
                                                                    this.setState({ eventAddress: r, isBlocked: true })
                                                                    this.validateField('eventAddress', r)
                                                                    //   , () => { this.validateField('eventAddress', r) })
                                                                }} */
                                                            ></textarea>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol id="invitationAddress" size="12">
                                                            <div class="form-check">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id="flexCheckDefault"
                                                                    checked={this.state.isSingleInvAddress}
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            isSingleInvAddress: e.target.checked,
                                                                            eventInvAddress: e.target.checked ? this.state.eventInvAddress : '',
                                                                            isBlocked: true
                                                                        }, () => { this.validateField('invitationAddressChk', null) })
                                                                    }}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="flexCheckDefault">
                                                                    Use Single Invitation Address
                                                            </label>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow size="12">
                                                        <MDBCol>
                                                            <label>Invitation Address:</label>
                                                            <textarea disabled={!this.state.isSingleInvAddress}
                                                                class="textareabox textAreaWidth"
                                                                id="invAddress"
                                                                rows="3"
                                                                defaultValue={this.state.eventInvAddress != null ? this.state.eventInvAddress : ''}
                                                                onChange = {e=>this.eventInvAddressChange('eventInvAddress',e.target.value)}
                                                                /* onBlur={e => {
                                                                    this.setState({ eventInvAddress: e.target.value, isBlocked: true }
                                                                        , () => { this.validateField('invAddress', '') })
                                                                }} */
                                                            ></textarea>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBContainer>
                                            </div>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                                {this.state.eventInvitees != null ?
                                    <>
                                        <Grid
                                            data={this.state.eventInvitees}
                                            recipientToAddBySearch={this.state.recipientToAddBySearch}
                                            fromPage="event"
                                            defaultTab="eventsTab"
                                            addInviteeFromSearch={this.addInviteeFromSearch}
                                            removeInviteFromSearch={this.removeInviteFromSearch}
                                            deleteAllInvitees={this.deleteAllInvitees}
                                            setImportState={this.setImportState}
                                            updatePeopleFromImport={this.updatePeopleFromImport.bind(this)}
                                            addPeople={this.addPeople}
                                            editCallbackFromGrid={this.editCallbackFromGrid}
                                            disabled={this.state.disabled}
                                        ></Grid></> : null}
                            </div>
                        </form>
                    </div>
                    <NotificationContainer />
                </>)
                }
            </>
        );
    }
}

export default CreateEventPage;
